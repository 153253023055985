<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">未上架</span>
      > 新增卖品类商品
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <el-form ref="form" label-width="180px" style="margin-top: 30px">

          <el-form-item size="small" label="商品/服务名称:" required>
            <el-input v-model="scName" placeholder="请输入商品/服务名称" maxlength="64" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="商品类型:" required>
            <el-radio-group v-model="scType">
              <el-radio :label="'0'">普通商品</el-radio>
              <el-radio :label="'1'">特卖</el-radio>
              <el-radio :label="'2'">拼团</el-radio>
            </el-radio-group>
          </el-form-item>

          <!--<el-form-item size="small" label="商品属性关联:" required>-->
            <!--<el-select size="small" style="width: 405px" v-model="attributeId" placeholder="请选择">-->
              <!--<el-option-->
                <!--v-for="item in attributeList"-->
                <!--:key="item.id"-->
                <!--:label="item.attributeName"-->
                <!--:value="item.id">-->
              <!--</el-option>-->
            <!--</el-select>-->
          <!--</el-form-item>-->

          <el-form-item size="small" label="开始售卖时间:" required>
            <el-date-picker
              v-model="startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>

          <el-form-item size="small" label="结束售卖时间:" required>
            <el-date-picker
              v-model="endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>

          <el-form-item size="small" label="分类:" required>
            <el-select size="small" style="width: 405px" v-model="categoryId" placeholder="请选择">
              <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.categoryName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item size="small" label="价格:" required>
            <el-input v-model="skuPrice" placeholder="请输入价格" maxlength="12" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item v-if="scType=='2'" size="small" label="拼团价格:" required>
            <el-input v-model="groupPrice" placeholder="请输入价格" maxlength="12" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="门店原价:" required>
            <el-input v-model="storePrice" placeholder="请输入门店原价" maxlength="12" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="最大可售卖数:" required>
            <el-input v-model="maximumSales" placeholder="请输入最大可售卖数" maxlength="15" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="已售数量基数:" required>
            <el-input v-model="salesBase" placeholder="请输入已售数量基数" maxlength="15" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="单用户允许购买数量:" required>
            <el-input v-model="allowBuyNum" placeholder="请输入购买数量" maxlength="15" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>

          <el-form-item size="small" label="上传商品图片:" required>
            <div class="graphic-description-value">
              <div>
                <el-upload
                  class="e-file-upload"
                  :action="Base_url+'/admin/uploadImg'"
                  :on-success="imageSuccess"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                >
                  <el-image v-if="imgUrl"
                            style="width: 137px; height: 137px"
                            :src="imgUrl"
                            fit="contain"></el-image>
                  <div class="e-upload--text" v-if="!imgUrl">
                    <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                    <p>添加上传图片</p>
                  </div>
                  <p class="e-file-upload-delete" v-if="imgUrl"
                     @click.stop="imgUrl=''">
                    <i class="el-icon-delete"></i>
                  </p>
                </el-upload>
              </div>
            </div>
          </el-form-item>

          <el-form-item size="small" label="商品/服务描述:" required>
            <el-input v-model="description" type="textarea"
                      :rows="6"
                      placeholder="请输入门店原价" maxlength="255" show-word-limit
                      style="width: 405px"></el-input>
          </el-form-item>
          <el-form-item size="small" label="图文详情:" required>
            <div class="graphic-description-iteditorValem">
              <quill-editor v-model="details"
                            value="editorVal"
                            ref="myQuillEditor"
                            :options="editorOption"
                            @change="updateValue"
                            style="width: 800px;height: 400px"
              >
              </quill-editor>
              <div style="display: none;" id="upvideoshow" @click='upVideo'></div>
              <div style="display: none;" id="upimgshow" @click='upImg'></div>
            </div>
          </el-form-item>
        </el-form>
        <div class="card-footer" style="width: 550px;margin-bottom: 30px;margin-top: 100px">
          <el-button size="small" @click="closeEvent">取 消</el-button>
          <el-button size="small" type="primary" @click="saveMspSku">确 定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Base_url} from '../../../config/index'

  import {quillEditor, quill} from 'vue-quill-editor'

  export default {
    data() {
      return {
        Base_url: '',

        scName: '',
        scType: '0',
        largeType: '',
        attributeId: '',
        categoryId: '',
        startTime: '',
        endTime: '',
        skuPrice: '',
        groupPrice:'',
        storePrice: '',
        imgUrl: '',
        videoUrl: '',
        description: '',
        details: '',
        maximumSales: '',
        salesBase: '',
        allowBuyNum: '',

        categoryList: [],
        // attributeList: [],

        editorOption: {
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'font': []}],
                [{'align': []}],
                ['clean'],
                ['link', 'image', 'video'],
                ["showHtml"]
              ],
              handlers: {
                'image': function () {
                  document.querySelector('#upimgshow').click()
                },
                'video': function (val) {
                  document.querySelector('#upvideoshow').click()
                },
                showHtml: () => {
                  if (this.txtArea.style.display === "") {
                    const html = this.txtArea.value;
                    if (html === '<p><br/></p>') {
                      this.html = null;
                    } else {
                      this.html = html.replace(new RegExp('<p><br/>', 'g'), '<p>')
                    }
                    this.quill.pasteHTML(html);
                  }
                  this.txtArea.style.display =
                    this.txtArea.style.display === "none" ? "" : "none";
                }
              }
            }
          },
          theme: 'snow',
          placeholder: '填写内容'
        },
      }
    },

    created() {
      this.Base_url = Base_url
      this.getCategoryList();
      // this.getAttributeList();
    },
    methods: {

      async getAttributeList() {
        let res = await this.$get("/admin/getMspAttributeList")
        this.attributeList = res.attributeList
      },

      async getCategoryList() {
        let data = {
          categoryType: '1'
        }
        let res = await this.$get("/admin/getMspCategoryList", data)
        this.categoryList = res.categoryList
      },


      async saveMspSku() {
        if (!this.scName) {
          this.$message.error("请输入服务/商品名称");
          return
        }
        if (!this.scType) {
          this.$message.error("请选择类型");
          return
        }
        if (!this.startTime) {
          this.$message.error("请选择开始时间");
          return
        }
        if (!this.endTime) {
          this.$message.error("请选择结束时间");
          return
        }
        if (!this.categoryId) {
          this.$message.error("请选择分类");
          return
        }
        if (!this.skuPrice) {
          this.$message.error("请输入商品价格");
          return
        }
        if (!this.storePrice) {
          this.$message.error("请输入门店原价");
          return
        }
        // if(!this.imgUrl) {
        //   this.$message.error("请上传图片");
        //   return
        // }
        // if(!this.videoUrl) {
        //   this.$message.error("请上传视频");
        //   return
        // }
        if (!this.description) {
          this.$message.error("请输入描述");
          return
        }

        // if (this.scType=='2') {
        //   if (this.verifiableType)
        //   return
        // }

        let data = {
          scName: this.scName,
          scType: this.scType,
          largeType: '1',
          startTimeStr: this.startTime,
          endTimeStr: this.endTime,
          attributeId: this.attributeId,
          categoryId: this.categoryId,
          skuPrice: this.skuPrice,
          groupPrice: this.groupPrice,
          storePrice: this.storePrice,
          videoUrl: this.videoUrl,
          imgUrl: this.imgUrl,
          description: this.description,
          details: this.details,
          maximumSales: this.maximumSales,
          salesBase: this.salesBase,
          allowBuyNum: this.allowBuyNum,
        };

        let res = await this.$post("/admin/save_mspSku", data)
        if (res.code == 200) {
          this.$message.success(res.message);
          this.$router.push('/microMalls/notShelf?index=1')
        } else {
          this.$message.error(res.message);
        }
      },


      async upVideo() {//上传视频
        let that = this;
        let oFile = document.createElement("input");
        oFile.setAttribute("type", "file");
        oFile.click();
        oFile.onchange = async (e) => {
          event.preventDefault();
          let formData = new FormData();
          formData.append('file', e.target.files[0]);
          let res = await this.$fileUpload("/admin/uploadImg", formData);
          let url = res.fileUrl
          // 获取光标所在位置
          let quill = that.$refs.myQuillEditor.quill
          let length = quill.getSelection().index;
          // 插入视频  response.data.url为服务器返回的图片地址
          quill.insertEmbed(length, 'video', url)
          // 调整光标到最后
          quill.setSelection(length + 1)
        }
      },
      async upImg() {//上传图片
        let that = this;
        let oFile = document.createElement("input");
        oFile.setAttribute("type", "file");
        oFile.click();
        oFile.onchange = async (e) => {
          event.preventDefault();
          let formData = new FormData();
          formData.append('file', e.target.files[0]);
          let res = await this.$fileUpload("/admin/uploadImg", formData);
          let url = res.fileUrl
          // 获取光标所在位置
          let quill = that.$refs.myQuillEditor.quill
          let length = quill.getSelection().index;
          // 插入图片  response.data.url为服务器返回的图片地址
          quill.insertEmbed(length, 'image', url)
          // 调整光标到最后
          quill.setSelection(length + 1)
          that.upimgShow = false
        }
      },
      updateValue() {
        this.$emit('input', this.$refs.myQuillEditor._content)
      },
      deleteVideo() {
        this.mediaUrl = ''
      },
      videoSuccess(res) {
        this.mediaUrl = res.mediaUrl
        this.coverUrl = res.coverUrl
        this.fileId = res.fileId
        this.fileSize = res.fileSize
        this.mediaName = res.mediaName
        this.requestId = res.requestId
      },
      showHTMLEvent() {
        this.quill = this.$refs.myQuillEditor.quill;
        this.txtArea = document.createElement("textarea");
        this.txtArea.style.cssText = "font-family:arial;width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 13px;outline: none;padding: 10px;line-height: 20px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;";

        const htmlEditor = this.quill.addContainer("ql-custom");
        htmlEditor.appendChild(this.txtArea);

        this.quill.on("text-change", (delta, oldDelta, source) => {
          var html = this.$refs.myQuillEditor._content;
          html = html.replace(/<p>(\S)/g, "<p>\n    $1"),
            html = html.replace(/(\S)<br><\/p>/g, "$1</p>"),
            html = html.replace(/(\S)<\/p>/g, "$1\n</p>"),
            html = html.replace(/<\/p><p>/g, "</p>\n<p>"),
            html = html.replace(/<\/p></g, "</p>\n<"),
            html = html.replace(/><p>/g, ">\n<p>"),
            html = html.replace(/">(\S)/g, '">\n$1'),
            html = html.replace(/(\S)<\/pre>/g, "$1\n</pre>"),
            html = html.replace(/><li>/g, ">\n    <li>"),
            html = html.replace(/><\/ol>/g, ">\n</ol>"),
            html = html.replace(/><div>/g, ">\n<div>"),
            this.txtArea.value = html;
        });
        this.content = this.value;
      },

      //图片相关
      imageSuccess(res) {
        this.imgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },


      closeEvent() {
        this.$router.push('/microMalls/notShelf?index=1')
      },


    },
    components: {
      quillEditor
    }
  }
</script>

<style>

</style>
